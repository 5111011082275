import * as THREE from 'three';
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import '@babel/polyfill';
import headObj from 'url:./model/head_planes2.obj'; // Import the .obj file as URL

window.onload = async function () {
  const clock = new THREE.Clock(); // Add this line at the beginning of the window.onload function
  // Create the scene, camera, renderer, and light
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  // Set camera position
  camera.position.set(0, 0, 50);
  const renderer = new THREE.WebGLRenderer({canvas: document.getElementById("canvas"), alpha: true});
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setClearColor(0x000000, 0); // Set the clear color to black and alpha to 0 (transparent)

// Create a cube to represent the space
  const spaceSize = 150;
  const spaceGeometry = new THREE.BoxGeometry(spaceSize, spaceSize, spaceSize);
  const edgesGeometry = new THREE.EdgesGeometry(spaceGeometry);
  const spaceMaterial = new THREE.LineBasicMaterial({color: 0xffffff});
  const space = new THREE.LineSegments(edgesGeometry, spaceMaterial);
  scene.add(space);

  // Create Light Source
  const light = new THREE.PointLight(0xffffff, 1, 250);
  light.position.set(20, -25, 100);
  light.intensity = 2;
  scene.add(light);

  const loader = new OBJLoader();
  const response = await fetch(headObj);
  const objText = await response.text();
  const object = loader.parse(objText);

  // Assign a new material to the head model
  let headMaterial = null;
  object.traverse(function (child) {
    if (child instanceof THREE.Mesh) {
      headMaterial = new THREE.MeshPhongMaterial({
        color: 0xffffff,
        shininess: 30,
        specular: 0x222222,
        transparent: true, // Add transparency support
        opacity: 1, // Set the initial opacity to 1 (fully visible)
      });
      headMaterial.name = "head";
      child.material = headMaterial;
    }
  });

  // Find the index of the "head" material in the materials array
  let headMaterialIndex = -1;
  object.traverse(function (child) {
    if (child instanceof THREE.Mesh && child.material.name === "head") {
      headMaterialIndex = child.material.index;
    }
  });

  // Add these lines to create the mixer without the fade-in effect
  const mixer = new THREE.AnimationMixer(object);
  scene.add(object);

  object.rotation.y += Math.PI; // Add this line to rotate the object by 180 degrees

  // Show lightbulb after head is loaded
  document.getElementById('lightbulb').style.display = 'block';

  const lightbulb = document.getElementById('lightbulb');

  requestAnimationFrame(() => {
    const lightScreenPos = new THREE.Vector3();
    lightScreenPos.copy(light.position);
    lightScreenPos.project(camera);

    const canvasBounds = renderer.domElement.getBoundingClientRect();
    const x = (lightScreenPos.x * 0.5 + 0.5) * canvasBounds.width - lightbulb.clientWidth / 2 + canvasBounds.left;
    const y = (-(lightScreenPos.y * 0.5) + 0.5) * canvasBounds.height - lightbulb.clientHeight / 2 + canvasBounds.top;

    lightbulb.style.left = `${Math.max(Math.min(x, canvasBounds.right - lightbulb.clientWidth), canvasBounds.left)}px`;
    lightbulb.style.top = `${Math.max(Math.min(y, canvasBounds.bottom - lightbulb.clientHeight), canvasBounds.top)}px`;
  });

  // Hide the loading div once the model is loaded
  document.getElementById('loading').style.display = 'none';

  // Add the ability for the user to zoom in/out using mouse controls
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enablePan = false; // Disable pan functionality
  controls.enableRotate = false; // Disable rotation by OrbitControls

  // Raycaster and mouse vector for detecting if the mouse is over the model
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  let modelFixed = false;
  let draggingLight = false;
  let draggingModel = false;

  // Add 'mousedown' event listener for model fixation and light bulb dragging
  lightbulb.addEventListener('mousedown', (event) => {
    event.preventDefault();
    draggingLight = true;
    lightbulb.style.cursor = 'grabbing';
  });
  renderer.domElement.addEventListener('mousedown', (event) => {
    event.preventDefault();
    const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
    const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
    mouse.set(mouseX, mouseY);
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObject(object, true);
    if (intersects.length > 0) {
      draggingModel = true;
    }
  });

  lightbulb.addEventListener('touchstart', (event) => {
    event.preventDefault();
    draggingLight = true;
  }, {passive: false});
  renderer.domElement.addEventListener('touchstart', (event) => {
    event.preventDefault();
    const mouseX = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
    const mouseY = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
    mouse.set(mouseX, mouseY);
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObject(object, true);
    if (intersects.length > 0) {
      draggingModel = true;
    }
  }, {passive: false});

  let prevMouseX = 0;
  let prevMouseY = 0;

  // Add 'mousemove' event listener for updating light bulb position and rotating the head
  renderer.domElement.addEventListener('mousemove', (event) => {
    event.preventDefault();
    const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
    const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
    mouse.set(mouseX, mouseY);

    // Calculate the change in mouse position since last frame
    const deltaX = event.clientX - prevMouseX;
    const deltaY = event.clientY - prevMouseY;

    // Update previous mouse position
    prevMouseX = event.clientX;
    prevMouseY = event.clientY;

    // Perform raycasting
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObject(object, true);

    // Change the cursor to a hand pointer if hovering over the model
    if (intersects.length > 0) {
      renderer.domElement.style.cursor = 'grab';
    } else {
      renderer.domElement.style.cursor = 'default';
    }

    if (draggingLight) {
      const vector = new THREE.Vector3(mouseX, mouseY, 0.5);
      vector.unproject(camera);
      const dir = vector.sub(camera.position).normalize();
      const distance = -camera.position.z / dir.z;
      const pos = camera.position.clone().add(dir.multiplyScalar(distance));
      light.position.copy(pos);

      const zValue = calculateZValue(event.clientX, event.clientY);
      light.position.z = clamp(zValue, parseFloat(zSlider.min), parseFloat(zSlider.max));
      updateSliders();

      // Get the light's screen position
      const lightScreenPos = new THREE.Vector3();
      lightScreenPos.copy(light.position);
      lightScreenPos.project(camera);

      const x = (lightScreenPos.x * 0.5 + 0.5) * window.innerWidth;
      const y = (-(lightScreenPos.y * 0.5) + 0.5) * window.innerHeight;

      // Update the lightbulb's position on the screen
      lightbulb.style.left = `${event.clientX - lightbulb.clientWidth / 2}px`;
      lightbulb.style.top = `${event.clientY - lightbulb.clientHeight / 2}px`;

    } else if (intersects.length > 0 && draggingModel) {
      // Rotate the head model when the mouse is pressed down on it and the model is not fixed

      // Calculate the scaling factor based on the size of the model and the distance of the mouse movement
      const scaleFactor = 0.005 * object.scale.length() * Math.abs(deltaX + deltaY);
      // Apply the rotation to the object
      object.rotation.y += deltaX * scaleFactor;
      object.rotation.x += deltaY * scaleFactor;

      // Calculate target rotation values
      const targetRotationY = object.rotation.y + deltaX * scaleFactor;
      const targetRotationX = object.rotation.x + deltaY * scaleFactor;

      // Use lerp to smoothen the rotation
      object.rotation.y = THREE.MathUtils.lerp(object.rotation.y, targetRotationY, 0.1);
      object.rotation.x = THREE.MathUtils.lerp(object.rotation.x, targetRotationX, 0.1);

    }
  });
  renderer.domElement.addEventListener('touchmove', (event) => {
    event.preventDefault();
    const mouseX = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
    const mouseY = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
    mouse.set(mouseX, mouseY);

    // Perform raycasting
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObject(object, true);

    if (draggingLight) {
      const vector = new THREE.Vector3(mouseX, mouseY, 0.5);
      vector.unproject(camera);
      const dir = vector.sub(camera.position).normalize();
      const distance = -camera.position.z / dir.z;
      const pos = camera.position.clone().add(dir.multiplyScalar(distance));
      light.position.copy(pos);

      const zValue = calculateZValue(event.touches[0].clientX, event.touches[0].clientY);
      light.position.z = clamp(zValue, parseFloat(zSlider.min), parseFloat(zSlider.max));
      updateSliders();

      // Update the lightbulb's position on the screen
      lightbulb.style.left = `${event.touches[0].clientX - lightbulb.clientWidth / 2}px`;
      lightbulb.style.top = `${event.touches[0].clientY - lightbulb.clientHeight / 2}px`;
    } else if (intersects.length > 0 && draggingModel) {
      // Rotate the head model when the touch is pressed down on it and the model is not fixed
      object.rotation.y = mouseX * 2 * Math.PI;
      object.rotation.x = -mouseY * 2 * Math.PI;

      // Calculate target rotation values
      const targetRotationY = mouseX * 2 * Math.PI;
      const targetRotationX = -mouseY * 2 * Math.PI;

      // Use lerp to smoothen the rotation
      object.rotation.y = THREE.MathUtils.lerp(object.rotation.y, targetRotationY, 0.1);
      object.rotation.x = THREE.MathUtils.lerp(object.rotation.x, targetRotationX, 0.1);
    }
  }, {passive: false});

  // Add 'mouseup' event listener to stop dragging the lightbulb and the model
  window.addEventListener('mouseup', () => {
    if (draggingLight) {
      draggingLight = false;
      lightbulb.style.cursor = 'grab';
    }
    if (draggingModel) {
      draggingModel = false;
    }
  });
  window.addEventListener('touchend', () => {
    if (draggingLight) {
      draggingLight = false;
    }
    if (draggingModel) {
      draggingModel = false;
    }
  }, {passive: false});

  // Resize the renderer when the window is resized
  window.addEventListener('resize', () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    renderer.setSize(width, height);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
  });

  // Set the initial position of the lightbulb element
  const setInitialLightbulbPosition = () => {
    const lightScreenPos = new THREE.Vector3();
    lightScreenPos.copy(light.position);
    lightScreenPos.project(camera);

    const canvasBounds = renderer.domElement.getBoundingClientRect();
    const x = (lightScreenPos.x * 0.5 + 0.5) * canvasBounds.width - lightbulb.clientWidth / 2 + canvasBounds.left;
    const y = (-(lightScreenPos.y * 0.5) + 0.5) * canvasBounds.height - lightbulb.clientHeight / 2 + canvasBounds.top;

    lightbulb.style.left = `${Math.max(Math.min(x, canvasBounds.right - lightbulb.clientWidth), canvasBounds.left)}px`;
    lightbulb.style.top = `${Math.max(Math.min(y, canvasBounds.bottom - lightbulb.clientHeight), canvasBounds.top)}px`;
  };

  setInitialLightbulbPosition();

// Get slider elements and value display elements
  const xSlider = document.getElementById('x-slider');
  const ySlider = document.getElementById('y-slider');
  const zSlider = document.getElementById('z-slider');
  const xValue = document.getElementById('x-value');
  const yValue = document.getElementById('y-value');
  const zValue = document.getElementById('z-value');

  // Set initial slider values based on the light position
  xSlider.value = light.position.x;
  ySlider.value = light.position.y;
  zSlider.value = light.position.z;

  // Initialize value display elements with initial slider values
  xValue.innerText = xSlider.value;
  yValue.innerText = ySlider.value;
  zValue.innerText = zSlider.value;

  // Event listeners for slider input events
  xSlider.addEventListener('input', (event) => {
    light.position.x = parseFloat(event.target.value);
    xValue.innerText = event.target.value;
  });

  ySlider.addEventListener('input', (event) => {
    light.position.y = parseFloat(event.target.value);
    yValue.innerText = event.target.value;
  });

  zSlider.addEventListener('input', (event) => {
    light.position.z = parseFloat(event.target.value);
    zValue.innerText = event.target.value;
  });

  // Calculate the Z value based on a two-dimensional bulb movement
  function calculateZValue(x, y) {
    const mouseX = (x / window.innerWidth) * 2 - 1;
    const mouseY = -(y / window.innerHeight) * 2 + 1;

    const vector = new THREE.Vector3(mouseX, mouseY, 0.5);
    vector.unproject(camera);
    const dir = vector.sub(camera.position).normalize();

    const planeNormal = new THREE.Vector3(0, 0, 1);
    const planePoint = new THREE.Vector3(0, 0, light.position.z);
    const distance = planeNormal.dot(camera.position.clone().sub(planePoint)) / planeNormal.dot(dir);

    const pos = camera.position.clone().add(dir.multiplyScalar(distance));
    return pos.z;
  }


  function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
  }

  function updateSliders() {
    xSlider.value = light.position.x;
    ySlider.value = light.position.y;
    const clampedZ = clamp(light.position.z, parseFloat(zSlider.min), parseFloat(zSlider.max));
    zSlider.value = clampedZ;

    xValue.innerText = xSlider.value;
    yValue.innerText = ySlider.value;
    zValue.innerText = zSlider.value;
  }

  function downloadImage() {
    // Remove the cube from the scene temporarily
    scene.remove(space);

    // Set the background color to black
    renderer.setClearColor(0x000000, 1);

    // Render the scene to the canvas
    renderer.render(scene, camera);

    // Convert the canvas data to a base64 encoded PNG image
    const dataURL = renderer.domElement.toDataURL("image/png");

    // Create an anchor element and set the download attribute
    const downloadLink = document.createElement("a");
    downloadLink.href = dataURL;
    downloadLink.download = "faceplanes.png";

    // Add the cube back to the scene
    scene.add(space);

    // Reset the background color to transparent
    renderer.setClearColor(0x000000, 0);

    // Simulate a click event to download the image
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  document.getElementById("download-button").addEventListener("click", downloadImage);

  // Render the scene in a loop
  function render() {
    setInitialLightbulbPosition(); // Update lightbulb position
    requestAnimationFrame(render);
    const delta = clock.getDelta(); // Add this line
    // mixer.update(delta); // Add this line
    renderer.render(scene, camera);
  }
  render();
};
